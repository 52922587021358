import { CoverImageProps } from '../types'

export const CoverImage8 = (props: CoverImageProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 120 120" {...props}>
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M129.44-7.29c0 11.343-11.396 11.343-11.396 22.792m.001 0c0 11.452 11.396 11.452 11.396 22.791 0 11.342-11.396 11.342-11.396 22.796m0 0c0 11.447 11.396 11.447 11.396 22.79 0 11.341-11.396 11.341-11.396 22.788m0 0c0 11.452 11.396 11.452 11.396 22.791M124.886-7.29c0 11.343-11.398 11.343-11.398 22.792"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M113.487 15.502c0 11.452 11.398 11.452 11.398 22.791 0 11.342-11.398 11.342-11.398 22.796m0 0c0 11.447 11.398 11.447 11.398 22.79 0 11.341-11.398 11.341-11.398 22.788m0 0c0 11.452 11.398 11.452 11.398 22.791M120.326-7.29c0 11.343-11.401 11.343-11.401 22.792"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M108.925 15.502c0 11.452 11.401 11.452 11.401 22.791 0 11.342-11.401 11.342-11.401 22.796m0 0c0 11.447 11.401 11.447 11.401 22.79 0 11.341-11.401 11.341-11.401 22.788m0 0c0 11.452 11.401 11.452 11.401 22.791m-4.669-136.83c.018 11.343-11.377 11.361-11.358 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M104.299 15.438c.018 11.453 11.412 11.435 11.431 22.773.019 11.341-11.377 11.359-11.358 22.814m-.001.001c.019 11.446 11.414 11.428 11.432 22.771.019 11.342-11.377 11.36-11.359 22.807m-.001 0c.019 11.453 11.414 11.435 11.432 22.773M111.096-7.365c.018 11.344-11.375 11.362-11.356 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M99.739 15.445c.018 11.453 11.41 11.435 11.429 22.773.018 11.341-11.375 11.359-11.356 22.814m0 .001c.019 11.447 11.411 11.428 11.429 22.771.019 11.341-11.374 11.359-11.356 22.806m-.001 0c.019 11.453 11.411 11.435 11.429 22.773m-4.778-136.74c.018 11.343-11.369 11.361-11.351 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M95.185 15.453c.019 11.452 11.404 11.434 11.423 22.772.018 11.342-11.368 11.36-11.35 22.815m0 .001c.019 11.446 11.405 11.428 11.423 22.771.019 11.341-11.368 11.36-11.351 22.807"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M95.33 106.618c.019 11.453 11.405 11.434 11.423 22.773M101.981-7.351c.018 11.344-11.375 11.363-11.356 22.811m0 0c.018 11.453 11.41 11.435 11.429 22.773.018 11.341-11.375 11.359-11.356 22.814m-.001.001c.019 11.446 11.412 11.428 11.43 22.771.019 11.341-11.375 11.359-11.357 22.806m0 0c.019 11.453 11.412 11.435 11.43 22.773M97.428-7.343C97.445 4 86.044 4.019 86.062 15.468"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M86.062 15.468C86.08 26.92 97.481 26.901 97.5 38.24c.018 11.342-11.383 11.36-11.364 22.814m0 .001c.019 11.447 11.419 11.429 11.437 22.771.02 11.341-11.382 11.359-11.364 22.807m-.001 0c.019 11.452 11.42 11.434 11.438 22.772M92.868-7.336c.018 11.343-11.384 11.362-11.365 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M81.503 15.475c.018 11.453 11.419 11.435 11.438 22.773.018 11.341-11.384 11.359-11.365 22.814m-.001 0c.019 11.446 11.42 11.428 11.438 22.771.019 11.342-11.383 11.36-11.365 22.807m-.001 0c.019 11.453 11.42 11.435 11.438 22.773M88.308-7.328c.018 11.343-11.377 11.361-11.358 22.81"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M76.949 15.481c.018 11.453 11.412 11.435 11.431 22.773.018 11.341-11.377 11.359-11.358 22.814m-.001.001c.019 11.447 11.414 11.429 11.432 22.771.019 11.341-11.377 11.359-11.359 22.807m0 0c.019 11.452 11.414 11.434 11.432 22.772M83.748-7.321c.018 11.342-11.38 11.361-11.361 22.81"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M72.387 15.489c.018 11.453 11.415 11.434 11.434 22.773.018 11.341-11.38 11.359-11.361 22.814m-.001.001c.02 11.446 11.416 11.428 11.434 22.771.019 11.342-11.378 11.36-11.36 22.807m-.001-.001c.019 11.453 11.415 11.435 11.433 22.773M79.191-7.314c.018 11.343-11.375 11.362-11.356 22.81"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M67.835 15.496c.018 11.453 11.411 11.435 11.43 22.773.018 11.341-11.376 11.359-11.356 22.814m-.001.001c.019 11.446 11.411 11.428 11.429 22.771.019 11.341-11.374 11.359-11.356 22.806m-.001 0c.019 11.453 11.411 11.435 11.429 22.773M74.632-7.307c.017 11.343-11.377 11.362-11.359 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M63.273 15.504c.018 11.452 11.412 11.434 11.431 22.772.018 11.342-11.377 11.36-11.358 22.815m0 .001c.019 11.446 11.413 11.428 11.431 22.771.019 11.341-11.376 11.359-11.358 22.807m-.001-.001c.019 11.452 11.414 11.434 11.432 22.773M70.078-7.3C70.096 4.044 58.701 4.062 58.72 15.511"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M58.72 15.511c.018 11.453 11.412 11.435 11.431 22.773.018 11.341-11.377 11.359-11.358 22.814m-.001.001c.019 11.446 11.413 11.428 11.431 22.771.019 11.341-11.376 11.359-11.358 22.806m-.001 0c.019 11.453 11.413 11.435 11.431 22.773M65.518-7.292c.018 11.343-11.375 11.361-11.356 22.81"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M54.161 15.518c.018 11.453 11.411 11.435 11.43 22.773.018 11.341-11.376 11.36-11.356 22.814m-.001 0c.019 11.447 11.411 11.429 11.429 22.771.019 11.341-11.374 11.359-11.356 22.807m0 .001c.019 11.452 11.411 11.434 11.429 22.772M60.964-7.285C60.981 4.058 49.581 4.076 49.6 15.525"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M49.6 15.525c.018 11.453 11.418 11.435 11.438 22.773.018 11.341-11.384 11.359-11.365 22.814m-.001.001c.019 11.446 11.42 11.428 11.438 22.771.019 11.342-11.383 11.36-11.365 22.807m-.001-.001c.019 11.453 11.42 11.435 11.438 22.773M56.398-7.278c.018 11.344-11.38 11.362-11.361 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M45.037 15.532c.018 11.453 11.415 11.435 11.434 22.773.018 11.341-11.38 11.359-11.361 22.814m-.001.001c.019 11.447 11.416 11.428 11.434 22.771.019 11.341-11.379 11.359-11.361 22.806m0 0c.02 11.453 11.416 11.435 11.434 22.773M51.842-7.271C51.859 4.072 40.467 4.091 40.485 15.54"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M40.485 15.54c.018 11.452 11.41 11.434 11.429 22.772.018 11.342-11.375 11.36-11.356 22.815m0 .001c.019 11.446 11.412 11.428 11.43 22.771.019 11.341-11.374 11.36-11.356 22.807m-.001-.001c.019 11.453 11.411 11.434 11.429 22.773M47.284-7.264c.018 11.344-11.38 11.362-11.36 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M35.924 15.547C35.941 27 47.338 26.981 47.356 38.32c.018 11.341-11.379 11.359-11.36 22.814m0 .001c.019 11.446 11.415 11.428 11.433 22.771.019 11.341-11.378 11.359-11.36 22.806m-.001 0c.019 11.453 11.416 11.435 11.434 22.773M42.722-7.256c.017 11.343-11.377 11.361-11.359 22.811"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M31.363 15.555c.019 11.452 11.412 11.434 11.432 22.772.018 11.342-11.378 11.36-11.359 22.814m0 .001c.018 11.447 11.414 11.428 11.431 22.771.019 11.341-11.377 11.359-11.359 22.807"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M31.508 106.72c.02 11.452 11.414 11.434 11.432 22.772M38.168-7.249C38.186 4.094 26.791 4.113 26.81 15.562m0 0c.019 11.453 11.412 11.435 11.431 22.773.018 11.341-11.377 11.359-11.358 22.814m-.001 0C26.9 72.596 38.296 72.577 38.313 83.92c.019 11.342-11.377 11.36-11.359 22.807m0 0c.019 11.453 11.414 11.435 11.432 22.773M33.614-7.24C33.632 4.102 22.23 4.12 22.25 15.568"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M22.25 15.568c.019 11.453 11.418 11.435 11.437 22.773.018 11.341-11.383 11.359-11.364 22.814m-.001.001c.019 11.447 11.42 11.429 11.438 22.771.019 11.341-11.383 11.359-11.365 22.807m0 0c.019 11.452 11.42 11.434 11.438 22.772M29.055-7.234c.017 11.342-11.386 11.361-11.367 22.81"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M17.688 15.576c.018 11.453 11.421 11.434 11.439 22.774.018 11.34-11.386 11.359-11.367 22.814m0 0c.019 11.446 11.422 11.428 11.439 22.771.019 11.342-11.385 11.36-11.367 22.807"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M17.832 106.741c.019 11.453 11.422 11.435 11.439 22.773M24.492-7.227c.018 11.344-11.377 11.362-11.358 22.81m0 0c.019 11.453 11.413 11.436 11.431 22.773.018 11.341-11.377 11.359-11.358 22.814m-.001.001c.019 11.446 11.413 11.428 11.431 22.771.02 11.341-11.376 11.359-11.358 22.806m-.001 0c.019 11.453 11.414 11.435 11.432 22.773M20.043-7.29c0 11.343-11.396 11.343-11.396 22.792"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M8.646 15.502c0 11.452 11.396 11.452 11.396 22.791 0 11.342-11.396 11.342-11.396 22.796m0 0c0 11.447 11.396 11.447 11.396 22.79 0 11.341-11.396 11.341-11.396 22.788m0 0c0 11.452 11.396 11.452 11.396 22.791M15.487-7.29c0 11.343-11.396 11.343-11.396 22.792"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M4.092 15.502c0 11.452 11.396 11.452 11.396 22.791 0 11.342-11.396 11.342-11.396 22.796m0 0c0 11.447 11.396 11.447 11.396 22.79 0 11.341-11.396 11.341-11.396 22.788m0 0c0 11.452 11.396 11.452 11.396 22.791M10.927-7.29C10.927 4.053-.47 4.053-.47 15.502"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M-.47 15.502c0 11.452 11.396 11.452 11.396 22.791C10.926 49.635-.47 49.635-.47 61.089m0 0c0 11.447 11.396 11.447 11.396 22.79C10.926 95.22-.47 95.22-.47 106.667m0 0c0 11.452 11.396 11.452 11.396 22.791M6.371-7.29c0 11.343-11.393 11.343-11.393 22.792"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M-5.021 15.502c0 11.452 11.393 11.452 11.393 22.791 0 11.342-11.393 11.342-11.393 22.796m0 0c0 11.447 11.393 11.447 11.393 22.79 0 11.341-11.393 11.341-11.393 22.788m0 0c0 11.452 11.393 11.452 11.393 22.791"
    />
    <path
      fill="none"
      stroke="#000"
      stroke-width="1.608"
      stroke-miterlimit="3.864"
      d="M-9.59 15.502c0 11.452 11.403 11.452 11.403 22.791 0 11.342-11.403 11.342-11.403 22.796m0 0c0 11.447 11.403 11.447 11.403 22.79C1.813 95.22-9.59 95.22-9.59 106.667m0 0c0 11.452 11.403 11.452 11.403 22.791"
    />
  </svg>
)
