import { CoverImageProps } from '../types'

export const CoverImage11 = (props: CoverImageProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 120 120" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="none"
      stroke="#000"
      stroke-width="4.949"
      stroke-miterlimit="3.864"
      d="M2.457-.255v5.262h5.262m9.47-5.262v5.262h5.261m9.469-5.262v5.262h5.262m9.47-5.262v5.262h5.262m9.469-5.262v5.262h5.261m9.47-5.262v5.262h5.262m9.471-5.262v5.262h5.261m9.469-5.262v5.262h5.261M2.457 29.209v5.261h5.262m9.47-5.261v5.261h5.261m9.469-5.261v5.261h5.262m9.47-5.261v5.261h5.262m9.469-5.261v5.261h5.261m9.47-5.261v5.261h5.262m9.471-5.261v5.261h5.261m9.469-5.261v5.261h5.261M2.457 58.671v5.261h5.262m9.47-5.261v5.261h5.261m9.469-5.261v5.261h5.262m9.47-5.261v5.261h5.262m9.469-5.261v5.261h5.261m9.47-5.261v5.261h5.262m9.471-5.261v5.261h5.261m9.469-5.261v5.261h5.261M2.457 88.133v5.261h5.262m9.47-5.261v5.261h5.261m9.469-5.261v5.261h5.262m9.47-5.261v5.261h5.262m9.469-5.261v5.261h5.261m9.47-5.261v5.261h5.262m9.471-5.261v5.261h5.261m9.469-5.261v5.261h5.261M2.457 117.596v2.261m14.732-2.261v2.261m14.73-2.261v2.261m14.732-2.261v2.261m14.731-2.261v2.261m14.731-2.261v2.261m14.733-2.261v2.261m14.73-2.261v2.261M-1.909 19.737H.353m9.47-5.26v5.26h5.262m9.468-5.26v5.26h5.261m9.471-5.26v5.26h5.262m9.471-5.26v5.26h5.261m9.469-5.26v5.26h5.261m9.47-5.26v5.26h5.262m9.469-5.26v5.26h5.261m9.471-5.26v5.26h5.261M-1.909 49.199H.353m9.47-5.26v5.26h5.262m9.468-5.26v5.26h5.261m9.471-5.26v5.26h5.262m9.471-5.26v5.26h5.261m9.469-5.26v5.26h5.261m9.47-5.26v5.26h5.262m9.469-5.26v5.26h5.261m9.471-5.26v5.26h5.261M-1.909 78.663H.353m9.47-5.262v5.262h5.262m9.468-5.262v5.262h5.261m9.471-5.262v5.262h5.262m9.471-5.262v5.262h5.261m9.469-5.262v5.262h5.261m9.47-5.262v5.262h5.262m9.469-5.262v5.262h5.261m9.471-5.262v5.262h5.261M-1.909 108.126H.353m9.47-5.261v5.261h5.262m9.468-5.261v5.261h5.261m9.471-5.261v5.261h5.262m9.471-5.261v5.261h5.261m9.469-5.261v5.261h5.261m9.47-5.261v5.261h5.262m9.469-5.261v5.261h5.261m9.471-5.261v5.261h5.261"
    />
  </svg>
)
