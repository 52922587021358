import { CoverImageProps } from '../types'

export const CoverImage4 = (props: CoverImageProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 120 120" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="none"
      stroke="#231F20"
      stroke-width=".974"
      stroke-miterlimit="3.864"
      d="M-1.149-.979 1.772 120M1.611-.979 4.533 120M4.37-.979 7.293 120M7.133-.979 10.054 120m-.159-92.634 2.92 120.98m2.599-74.662 2.921 100.977M15.414-.979 18.336 120M18.174-.979 21.096 120M20.935-.979 23.856 120m1.299-92.634 2.921 120.98m2.6-58.311 2.922 120.979M29.216-.979 32.137 120M31.978-.979 34.899 120m-4.382-89.798 2.921 120.98M37.499-.979 40.42 120M40.259-.979 43.182 120M43.019-.979 45.94 120m-5.681-89.798 2.922 120.98M48.541-.979 51.462 120M51.301-.979 54.222 120M54.062-.979 56.982 120M49.84 49.547l2.921 120.98M62.343-.979 65.265 120M65.102-.979 68.023 120M67.863-.979 70.785 120M70.624-.979 73.546 120m-2.922-87.462 2.922 120.98M78.904-.979 81.826 120M81.666-.979 84.587 120M84.426-.979 87.348 120M87.188-.979 90.109 120M89.948-.979 92.869 120M92.708-.979 95.63 120m-4.383-32.145 2.922 100.979M98.229-.979 101.15 120M100.989-.979 103.91 120M103.75-.979 106.672 120M106.51-.979 109.432 120M109.271-.979 112.192 120m-5.682-64.286 2.922 120.979M114.791-.979 117.713 120m-.16-120.979L120.474 120M120.312-.979 123.234 120"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="none"
      stroke="#231F20"
      stroke-width=".984"
      stroke-miterlimit="3.864"
      d="M6.316-.016V120m11.858-60.97v120.016m8.442-165.37v120.015M31.418-.016V120M39.784-.016V120m11.517-31.662v100.014m8.281-132.156v120.016m9.742-113.424v120.016M89.987-.016V120M98.354-.016V120M106.722-.016V120M115.088-.016V120"
    />
  </svg>
)
